import React, { useState } from "react";
import { graphql } from "gatsby"

// components
import Asset from '../components/asset/Asset';
import Layout from "../components/layout/Layout";
import Seo from "../components/seo/SEO";

// svgs 
import DownArrow from '../svgs/down_arrow.svg';

export const query = graphql`
  query ($id: [Craft_QueryArgument]) {
    craft {
      entries(id: $id) {
        title
        uri
        ... on Craft_books_books_Entry {
          pageSeo {
            description
            keywords {
              keyword
            }
            advanced {
                robots
                canonical
            }
            social {
              facebook {
                title
                description
                image {
                  ... on Craft_books_Asset {
                    url
                  }
                }
              }
              twitter {
                title
                description
                image {
                  ... on Craft_books_Asset {
                    url
                  }
                }
              }
            }
            title
          }
          linksMatrix {
            ... on Craft_linksMatrix_linkBlock_BlockType {
              linkTitle
              linkUrl
            }
          }
          bookMatrix {
            ... on Craft_bookMatrix_contentBlock_BlockType {
              typeHandle
              contentTitle
              richText
              author
              awards
              featuredImage {
                ... on Craft_books_Asset {
                  title
                  url
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const BookPage = ({data}) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  }

  const entry = data.craft.entries[0];
  const content = entry.bookMatrix[0];

  const seo = entry.pageSeo;

  let image = null;
  image = content.featuredImage ? content.featuredImage[0] : null;

  let watch = null;
  watch = entry.linksMatrix.length > 0 ? entry.linksMatrix : null;

  const watchSelect = <div className={`flex justify-between dropdown-button dropdown-button--margin`} aria-label={`button`} role={`button`} tabIndex={0} onClick={toggleDropdown} onKeyDown={toggleDropdown}>
    <h3 className={`m-0`}>Read Now</h3>
    <div className={`dropdown-button__arrow ${dropdown ? 'dropdown-button__arrow--rotate' : null}`}>
      <DownArrow />
    </div>
  </div>;

  let dropdownOptions = null;
  dropdownOptions = watch ? watch.map((item, i) => {
    return (
      <a key={i} className={`dropdown-item`} href={item.linkUrl} target="_blank" rel="noreferrer">
        <h2>{item.linkTitle}</h2>
      </a>
    )
  }) : null;

  let keywords = [];
  if (seo.keywords) {
    seo.keywords.forEach((keyword) => {
      keywords.push(keyword.keyword);
    })
  }

  return (
    <Layout>
      <Seo 
        title={entry.title} 
        canonical={seo.advanced.canonical}
        facebookImage={seo.social.facebook.image?.url}
        robots={seo.advanced.robots}
        twitterImage={seo.social.twitter.image?.url}
        description={seo.description ? seo.description : content.richText.replace(/<[^>]*>?/gm, '')}
        image={image.url}
        keywords={keywords}
      />

      <div className={`book grid grid--1-2`}>
        <div className={`book__left`}>
          {image ? <Asset data={image.imageFile} title={image.title} /> : null}

          <div className={`book__inner-left relative`}>
            <h1 className={`book__title tablet-only`}>{entry.title}</h1>

            {watch ? watchSelect : null}

            {dropdown ?
              <div className={`dropdown-container dropdown-container--margin`}>{dropdownOptions}</div>
              : null
            }
          </div>

          <div className={`detail__list detail__list--mobile-padding`}>
            <div className={`detail__list-item`}>
              <h4 className={`bold uppercase`}>Author</h4>
              <p>{content.author}</p>
            </div>

            {content.awards ? 
              <div className={`detail__list-item`}>
                <h4 className={`bold uppercase`}>Year</h4>
                <p>{content.awards}</p>
              </div>
            : null}
          </div>
        </div>

        <div className={`book__right`}>
          <h1 className={`no-tablet`}>{entry.title}</h1>
          <div className={`detail__copy`} dangerouslySetInnerHTML={{ __html: content.richText }} />
        </div>
      </div>
    </Layout>
  )
}

export default BookPage
